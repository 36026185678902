import { I18NService } from "@bluelibs/x-ui-i18n-bundle";
import { use } from "@bluelibs/x-ui-react-bundle";
import { useRouter } from "@bluelibs/x-ui-react-router-bundle";
import { useUISession } from "@bluelibs/x-ui-session-bundle";
import { AcceptedLanguage } from "@root/api.types";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
export const useLanguageSwitcher = () => {
    const i18nService = use(I18NService);
    const [activeLanguage, setActiveLanguage] = useState(AcceptedLanguage.en);
    const uiSession = useUISession();
    const router = useRouter();
    const lang = uiSession.get("currentLocale");
    const params = useParams();
    const fallbackLang = AcceptedLanguage.de;
    const navigatorLang = navigator.language.split("-")[0];
    useEffect(() => {
        if (navigator.userAgent.includes("Googlebot")) {
            return;
        }
        if (params.lang && Object.values(AcceptedLanguage).includes(params.lang)) {
            onLanguageChange(params.lang, true);
        }
        else if (lang) {
            onLanguageChange(lang, true);
        }
        else if (Object.values(AcceptedLanguage).includes(navigatorLang)) {
            onLanguageChange(navigatorLang, true);
        }
        else {
            onLanguageChange(fallbackLang, true);
        }
    }, []);
    const onLanguageChange = (language, persist) => {
        setActiveLanguage(language);
        i18nService.setLocale(language);
        if (language !== params.lang) {
            const currentUrl = window.location.href;
            const url = new URL(currentUrl);
            const pathname = url.pathname.replace(`/${params.lang}`, "");
            url.pathname = `/${language}${pathname}`;
            router.history.push(url.pathname + url.search);
        }
        if (persist) {
            uiSession.set("currentLocale", language, { persist: true });
        }
    };
    return {
        onLanguageChange,
        activeLanguage,
    };
};
