import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslate } from "@bluelibs/x-ui";
import { use, useSmart } from "@bluelibs/x-ui";
import { AssetActionType, AssetMediaRenderType, UserRole, } from "@root/api.types";
import { uniqueId } from "lodash-es";
import { AssetService, } from "../services/Asset.service";
import { IconWalk, IconLeft, IconRight, IconDoubleLeft, IconDoubleRight, IconBasket, IconTrash, IconDetails, IconAsset, IconClose, IconPlay, IconLeave, IconPlayPause, } from "./svg";
import { Button } from "./button";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { REQUEST_DETAILS_ABOUT_PRODUCT_ANALYTICS } from "../mutations";
import ObjectDetailsSidebar from "./object-details/ObjectDetailsSidebar";
import { ProjectSmart } from "../smarts/Project.smart";
export const ProjectInteractions = ({ basket = [], addToBasket, removeFromBasket, setIsObjectsOpen, isObjectsOpen, }) => {
    const t = useTranslate("components.projectInteraction");
    const projectSmart = useSmart(ProjectSmart);
    const state = projectSmart.state;
    const assetService = use(AssetService);
    const [isSeeingDetails, setIsSeeingDetails] = useState(false);
    const [requestDetailsAnalytics] = useMutation(REQUEST_DETAILS_ABOUT_PRODUCT_ANALYTICS);
    const currentlySelectedAsset = state.project.assetInfos.find((asset) => asset._id === state.currentlySelectedAssetId);
    const onAddToBasket = async () => {
        if (!currentlySelectedAsset)
            return;
        await addToBasket({
            productId: currentlySelectedAsset.product._id,
            projectId: currentlySelectedAsset.projectId,
        });
    };
    const onRemoveFromBasket = async () => {
        if (!currentlySelectedAsset)
            return;
        await removeFromBasket({
            productId: currentlySelectedAsset.product._id,
            projectId: currentlySelectedAsset.projectId,
        });
    };
    const teleportToCurrentlySelectedAsset = () => {
        projectSmart.updatePositions(currentlySelectedAsset);
    };
    const onCarouselIndexUpdate = (type, fileType) => {
        assetService.handleCarousel(currentlySelectedAsset, type, fileType);
    };
    const asset = currentlySelectedAsset;
    const product = currentlySelectedAsset === null || currentlySelectedAsset === void 0 ? void 0 : currentlySelectedAsset.product;
    const isProductInBasket = basket.some((item) => item.product._id === (currentlySelectedAsset === null || currentlySelectedAsset === void 0 ? void 0 : currentlySelectedAsset.product._id));
    const onSeeDetailsClick = () => {
        if (!isSeeingDetails && !state.isOwnerOfProject) {
            requestDetailsAnalytics({
                variables: {
                    input: {
                        assetInfoId: asset._id,
                    },
                },
            });
        }
        setIsSeeingDetails((prev) => !prev);
    };
    const selectAssetInMerchant = () => {
        projectSmart.sendMessageToParent({
            type: "asset-selected",
            value: { projectAssetInfoId: asset._id },
        });
    };
    if (!currentlySelectedAsset)
        return null;
    const carouselItems = [
        {
            icon: IconDoubleLeft,
            onClick: () => onCarouselIndexUpdate("first", currentlySelectedAsset.mediaType),
        },
        {
            icon: IconLeft,
            onClick: () => onCarouselIndexUpdate("prev", currentlySelectedAsset.mediaType),
        },
        {
            icon: IconRight,
            onClick: () => onCarouselIndexUpdate("next", currentlySelectedAsset.mediaType),
        },
        {
            icon: IconDoubleRight,
            onClick: () => onCarouselIndexUpdate("last", currentlySelectedAsset.mediaType),
        },
    ];
    const isWatching360 = state.isWatching360;
    const watch360Item = {
        icon: IconPlay,
        onClick: () => assetService.showSingle360(currentlySelectedAsset),
    };
    if (currentlySelectedAsset.mediaType ===
        AssetMediaRenderType.IMAGE_360_CAROUSEL &&
        !isWatching360) {
        carouselItems.splice(2, 0, watch360Item);
    }
    const takeAction = (action) => {
        return function () {
            action();
            setIsObjectsOpen(false);
        };
    };
    return (_jsxs("div", Object.assign({ style: isObjectsOpen ? { zIndex: 999 } : {}, className: "cc--flex cc-interaction-bar" }, { children: [product && isWatching360 && (_jsx("div", Object.assign({ className: "cc-interaction-bar__item cc-interaction-bar__close bottom-space" }, { children: _jsx(Button, Object.assign({ onClick: watch360Item.onClick, variant: "outlined", color: "primary", bgColor: "primary", size: "custom", className: "cc-interaction-bar__action-btn" }, { children: _jsx(IconClose, {}, void 0) }), void 0) }), void 0)), !isWatching360 && (_jsx("div", Object.assign({ style: { width: "100%" }, className: "cc--flex cc-interaction-bar--level-1" }, { children: _jsx("div", Object.assign({ className: "cc-interaction-bar__item cc-interaction-bar__close mb right" }, { children: _jsx(Button, Object.assign({ variant: "outlined", color: "primary", bgColor: "primary", size: "custom", className: "cc-interaction-bar__action-btn", onClick: () => projectSmart.updateState({ currentlySelectedAssetId: null }) }, { children: _jsx(IconClose, {}, void 0) }), void 0) }), void 0) }), void 0)), _jsxs("div", Object.assign({ className: "cc--flex cc-interaction-bar--level-1" }, { children: [!state.isWatching360 && (_jsxs("div", Object.assign({ className: "cc-interaction-bar__item" }, { children: [_jsx(Button, Object.assign({ variant: "outlined", color: "primary", bgColor: "primary", size: "custom", className: "cc-interaction-bar__action-btn", onClick: takeAction(teleportToCurrentlySelectedAsset) }, { children: _jsx(IconWalk, {}, void 0) }), void 0), _jsx("span", { children: t("walk") }, void 0)] }), void 0)), (() => {
                        const isVideoMediaType = asset.mediaType === AssetMediaRenderType.VIDEO;
                        if (!isVideoMediaType || !(asset === null || asset === void 0 ? void 0 : asset.product)) {
                            return;
                        }
                        return (_jsx("div", Object.assign({ className: "cc-interaction-bar__item" }, { children: _jsx(Button, Object.assign({ variant: "outlined", color: "primary", bgColor: "primary", size: "custom", className: "cc-interaction-bar__action-btn", onClick: () => assetService.playPauseVideo(asset) }, { children: _jsx(IconPlayPause, {}, void 0) }), void 0) }), void 0));
                    })(), (() => {
                        var _a;
                        const canGoToNextRoom = ((_a = currentlySelectedAsset.action) === null || _a === void 0 ? void 0 : _a.type) === AssetActionType.NEXT_ROOM;
                        const isMerchantMode = state.isEmbedded;
                        if (isMerchantMode || !canGoToNextRoom) {
                            return;
                        }
                        return (_jsxs("div", Object.assign({ className: "cc-interaction-bar__item" }, { children: [_jsx(Button, Object.assign({ variant: "outlined", color: "primary", bgColor: "primary", size: "custom", className: "cc-interaction-bar__action-btn", onClick: () => {
                                        projectSmart
                                            .getNextRoomActionProjectLink(currentlySelectedAsset)
                                            .then((res) => {
                                            if (res) {
                                                window.location.replace(res);
                                            }
                                        });
                                    } }, { children: _jsx(IconLeave, {}, void 0) }), void 0), _jsx("span", { children: t("nextRoom") }, void 0)] }), void 0));
                    })(), !state.isOwnerOfProject && state.role === UserRole.ENDUSER && (_jsxs("div", Object.assign({ className: "cc-interaction-bar__item" }, { children: [_jsx(Button, Object.assign({ variant: "outlined", color: "primary", bgColor: "primary", size: "custom", className: "cc-interaction-bar__action-btn", onClick: isProductInBasket
                                    ? takeAction(onRemoveFromBasket)
                                    : takeAction(onAddToBasket) }, { children: isProductInBasket ? _jsx(IconTrash, {}, void 0) : _jsx(IconBasket, {}, void 0) }), void 0), _jsxs("span", { children: [isProductInBasket ? t("removeFrom") : t("addTo"), " ", t("wishlist")] }, void 0)] }), void 0)), (asset === null || asset === void 0 ? void 0 : asset.product) && !state.isWatching360 && (_jsxs("div", Object.assign({ className: "cc-interaction-bar__item" }, { children: [_jsx(Button, Object.assign({ variant: "outlined", color: "primary", bgColor: "primary", size: "custom", className: "cc-interaction-bar__action-btn", onClick: takeAction(onSeeDetailsClick) }, { children: _jsx(IconDetails, {}, void 0) }), void 0), _jsxs("span", { children: [isSeeingDetails ? t("hide") : t("see"), " ", t("details")] }, void 0)] }), void 0))] }), void 0), _jsx(ObjectDetailsSidebar, { isOpen: isSeeingDetails, onClose: () => setIsSeeingDetails(false), product: product }, void 0), product &&
                [
                    AssetMediaRenderType.IMAGE_2D_CAROUSEL,
                    AssetMediaRenderType.IMAGE_360_CAROUSEL,
                ].includes(asset.mediaType) && (_jsx("div", Object.assign({ className: "cc--flex cc-interaction-bar--level-2" }, { children: carouselItems.map(({ icon, onClick }) => {
                    const Icon = icon;
                    return (_jsx("div", Object.assign({ className: "cc-interaction-bar__item" }, { children: _jsx(Button, Object.assign({ variant: "outlined", color: "primary", bgColor: "primary", size: "custom", className: "cc-interaction-bar__action-btn", onClick: takeAction(onClick) }, { children: _jsx(Icon, {}, void 0) }), void 0) }), uniqueId("carousel-btn-")));
                }) }), void 0)), product &&
                asset.mediaType === AssetMediaRenderType.IMAGE_360 &&
                !isWatching360 && (_jsx("div", Object.assign({ className: "cc--flex cc-interaction-bar--level-2" }, { children: [watch360Item].map(({ icon, onClick }) => {
                    const Icon = icon;
                    return (_jsx("div", Object.assign({ className: "cc-interaction-bar__item" }, { children: _jsx(Button, Object.assign({ variant: "outlined", color: "primary", bgColor: "primary", size: "custom", className: "cc-interaction-bar__action-btn", onClick: takeAction(onClick) }, { children: _jsx(Icon, {}, void 0) }), void 0) }), uniqueId("carousel-btn-")));
                }) }), void 0)), product && state.isEmbedded && (_jsxs("div", Object.assign({ className: "cc-interaction-bar__item cc-interaction-bar__item__space_top" }, { children: [_jsx(Button, Object.assign({ variant: "outlined", color: "primary", bgColor: "primary", size: "custom", className: "cc-interaction-bar__action-btn", onClick: takeAction(selectAssetInMerchant) }, { children: _jsx(IconAsset, {}, void 0) }), void 0), _jsx("span", { children: t("selectAsset") }, void 0)] }), void 0))] }), void 0));
};
