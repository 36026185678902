import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { use, useSmart } from "@bluelibs/x-ui";
import { AssetMediaRenderType } from "@root/api.types";
import { AssetService } from "../services/Asset.service";
// import { Button } from "antd";
import { uniqueId } from "lodash-es";
import { Fragment } from "react";
import Sidebar from "./sidebar/Sidebar";
import ProjectItem from "./project-item/ProjectItem";
import ProjectPositionForm from "./project-position/ProjectPositionForm";
import { IconList } from "./svg/icons";
import { Button } from "./button";
import { useTranslate } from "@bluelibs/x-ui-i18n-bundle";
import { ProjectSmart } from "../smarts/Project.smart";
export const ProjectObjects = ({ form, projectId, isOpen, setIsOpen, isMobile, }) => {
    const t = useTranslate("components.projectObjects");
    const assetService = use(AssetService);
    const projectSmart = useSmart(ProjectSmart);
    const { state } = projectSmart;
    const toggleSidebar = () => setIsOpen((prevOpen) => !prevOpen);
    const closeSidebar = () => setIsOpen(false);
    const closeForm = () => {
        projectSmart.updateState({ currentlySelectedAssetId: null });
    };
    const currentlySelectedAsset = state.project.assetInfos.find((asset) => asset._id === state.currentlySelectedAssetId);
    const product = currentlySelectedAsset === null || currentlySelectedAsset === void 0 ? void 0 : currentlySelectedAsset.product;
    return (_jsxs(Fragment, { children: [_jsxs(Button, Object.assign({ color: "primary", bgColor: "white", size: "custom", className: "cc-project-sidebar__toggle-btn", onClick: toggleSidebar }, { children: [_jsx(IconList, {}, void 0), t("objects")] }), void 0), isOpen && (_jsx(Sidebar, Object.assign({ isMobile: isMobile, title: t("objects"), onClose: closeSidebar }, { children: product && state.isOwnerOfProject ? (_jsx(ProjectPositionForm, { form: form, projectId: projectId, onBack: closeForm }, void 0)) : (_jsx("ul", Object.assign({ className: "cc-custom-list cc-object-list" }, { children: state.project.assetInfos.map((item) => {
                        var _a;
                        const assetData = assetService.getAssetData(item);
                        const { file, type } = assetData;
                        const avatarSrc = file &&
                            [
                                AssetMediaRenderType.IMAGE_2D,
                                AssetMediaRenderType.IMAGE_360,
                                AssetMediaRenderType.IMAGE_2D_CAROUSEL,
                            ].includes(type) &&
                            (Array.isArray(file) ? file[0] : file).downloadUrl;
                        const onClick = () => {
                            const isSelectingSame = (currentlySelectedAsset === null || currentlySelectedAsset === void 0 ? void 0 : currentlySelectedAsset._id) === assetData.id;
                            projectSmart.updateState({
                                currentlySelectedAssetId: isSelectingSame ? null : item._id,
                            });
                            if (currentlySelectedAsset && isSelectingSame) {
                                const entity = assetService.findWorldAssetEntityById(currentlySelectedAsset._id);
                                const initialPosition = assetService.calculatePositionWithDelta(currentlySelectedAsset.assetPosition.coordinates, currentlySelectedAsset.deltaCoordinates);
                                form.resetFields();
                                assetService.updatePositionsForAsset(entity, initialPosition);
                            }
                        };
                        const fileName = (_a = (Array.isArray(file) ? file[0] : file)) === null || _a === void 0 ? void 0 : _a.name;
                        return (_jsx(ProjectItem, { 
                            // type={type}
                            isActive: (currentlySelectedAsset === null || currentlySelectedAsset === void 0 ? void 0 : currentlySelectedAsset._id) === item._id, onClick: onClick, image: avatarSrc, name: assetService.removeExtensionFromFileName(fileName) }, uniqueId("object-item-")));
                    }) }), void 0)) }), void 0))] }, void 0));
};
