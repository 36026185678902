var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a, _b, _c, _d, _e, _f, _g, _h, _j;
import { EventManager, Inject, Service } from "@bluelibs/core";
import { Smart } from "@bluelibs/x-ui";
import { AcceptedLanguage, AssetMediaRenderType, AssetMediaTypeFor3D, MerchantsBillingPackageType, SubmitLogInputAction, SubmitLogInputMicroservice, UserRole, } from "@root/api.types";
import { AppGuardian, AssetService } from "../services";
import { ApolloClient } from "@bluelibs/ui-apollo-bundle";
import { PROJECTS_FIND_ONE } from "../queries/projectsFindOne.query";
import { AssetClickedEvent } from "../events/AssetClicked.event";
import env from "@root/startup/env";
import autoBind from "auto-bind";
import { UISessionService } from "@bluelibs/x-ui-session-bundle";
import { I18NService } from "@bluelibs/x-ui-i18n-bundle";
import { XRouter } from "@bluelibs/x-ui-react-router-bundle";
import { createContext } from "react";
import { WishlistSmart } from "./Wishlist.smart";
import { CHAT_WITH_MERCHANT, GET_VR_AVATAR, SUBMIT_LOG } from "../mutations";
import { PROJECTS_GET_NEXT_ROOM_ACTION_LINK } from "../queries/projectsGetNextRoomLink.query";
import { VRAssetService } from "../services/VRAsset.service";
export const ProjectSmartContext = createContext(null);
let ProjectSmart = class ProjectSmart extends Smart {
    constructor() {
        super();
        this.state = {};
        this.init();
        autoBind(this);
    }
    static getContext() {
        return ProjectSmartContext;
    }
    async init() {
        this.setState({
            isLoggedIn: undefined,
            isOwnerOfProject: undefined,
            isEmbedded: window.location !== window.parent.location,
            isFromMerchantsHeader: window.location.search.includes("fromMerchantsHeader"),
            hasReceivedInitFromMerchants: undefined,
            currentlySelectedAssetId: undefined,
            project: undefined,
            avatar: undefined,
            shop: undefined,
            isProductSelectedFromMerchants: false,
            isChatting: false,
            hasMicrophone: false,
            hasCamera: false,
            isWatching360: false,
            isLoadingProject: true,
            isLoadingAvatar: true,
            isLoadingShop: true,
            errorShop: null,
            linkName: undefined,
            role: null,
        });
    }
    get project() {
        return this.state.project;
    }
    get avatar() {
        return this.state.avatar;
    }
    set avatar(avatar) {
        this.state.avatar = avatar;
    }
    async setIsChatting() {
        const { hasChattedBefore } = this.state;
        this.updateState({ isChatting: true, hasChattedBefore: true });
        if (!hasChattedBefore) {
            this.apolloClient.mutate({
                mutation: CHAT_WITH_MERCHANT,
                variables: {
                    input: {
                        merchantId: this.uiSessionService.state.merchantId,
                    },
                },
            });
        }
    }
    async enterProject() {
        var _a, _b, _c;
        if (!this.state.linkName)
            return;
        try {
            const { data, error } = await this.apolloClient.query({
                query: PROJECTS_FIND_ONE(this.uiSessionService.state.currentLocale || AcceptedLanguage.en),
                fetchPolicy: "network-only",
                variables: {
                    input: {
                        linkName: this.state.linkName,
                    },
                },
            });
            this.updateState({
                errorProject: error,
                isLoadingProject: false,
                project: data === null || data === void 0 ? void 0 : data.EndUsersGetProject,
                role: (((_b = (_a = this.guardian.state.user) === null || _a === void 0 ? void 0 : _a.roles) === null || _b === void 0 ? void 0 : _b[0]) ||
                    UserRole.ENDUSER),
                isOwnerOfProject: this.isOwnerOfProject(data === null || data === void 0 ? void 0 : data.EndUsersGetProject),
            });
        }
        catch (err) {
            this.updateState({
                errorProject: err,
                isLoadingProject: false,
            });
        }
        // Getting VR Avatar object from DB
        if (this.guardian.state.isLoggedIn) {
            try {
                const { data, error } = await this.apolloClient.query({
                    query: GET_VR_AVATAR,
                    fetchPolicy: "network-only",
                });
                this.updateState({
                    errorAvatar: error,
                    isLoadingAvatar: false,
                    avatar: (_c = data === null || data === void 0 ? void 0 : data.UsersVRAvatarsGet) !== null && _c !== void 0 ? _c : null,
                });
            }
            catch (err) {
                this.updateState({
                    errorAvatar: err,
                    isLoadingAvatar: false,
                });
            }
        }
    }
    async getNextRoomActionProjectLink(asset) {
        try {
            const { data, error: _ } = await this.apolloClient.query({
                query: PROJECTS_GET_NEXT_ROOM_ACTION_LINK,
                fetchPolicy: "network-only",
                variables: { input: { projectAssetInfoId: asset._id } },
            });
            return data.EndUsersGetNextRoomActionProjectLink;
        }
        catch (err) {
            return null;
        }
    }
    canUseVideo() {
        const { billing: { features, packageType }, } = this.state.project;
        const isVr = AFRAME.utils.device.checkHeadsetConnected() &&
            !AFRAME.utils.device.isMobile();
        if (isVr) {
            return false;
        }
        if (packageType === MerchantsBillingPackageType.TITAN) {
            return true;
        }
        return false;
    }
    canUseAudio() {
        const { billing: { features, packageType }, } = this.state.project;
        if ([
            MerchantsBillingPackageType.GOLD,
            MerchantsBillingPackageType.TITAN,
        ].includes(packageType)) {
            return true;
        }
        return false;
    }
    canUseChat() {
        const { billing: { features, packageType }, } = this.state.project;
        if (this.guardian.state.isLoggedIn)
            return false;
        return packageType !== MerchantsBillingPackageType.BRONZE;
    }
    isOwnerOfProject(project) {
        var _a, _b;
        return project.merchantId === ((_b = (_a = this.guardian.state.user) === null || _a === void 0 ? void 0 : _a.merchant) === null || _b === void 0 ? void 0 : _b._id);
    }
    updatePosition(position) {
        const rigElement = document.getElementById("rig");
        rigElement.object3D.position.set(position.x, position.y, position.z);
    }
    updateRotation(rotation) {
        const cameraElement = document.getElementById("player");
        const cameraRig = document.getElementById("camera-rig");
        const lookControls = cameraElement.components["look-controls"];
        if (lookControls.el.sceneEl.is("vr-mode")) {
            console.log("Is VR");
        }
        else {
            lookControls.yawObject.rotation.y = rotation.y;
            lookControls.pitchObject.rotation.x = rotation.x;
        }
    }
    updatePositionAndRotation(position, rotation) {
        this.updatePosition(position);
        this.updateRotation(rotation);
    }
    getPlayerPositionAndRotation() {
        const rigElement = document.getElementById("rig");
        const cameraElement = document.getElementById("player");
        const position = rigElement.object3D.position;
        const worldPosition = new AFRAME.THREE.Vector3();
        rigElement.object3D.getWorldPosition(worldPosition);
        const rotation = cameraElement.object3D.rotation;
        return { position, worldPosition, rotation };
    }
    toggleNavMesh() {
        const rig = document.getElementById("rig");
        const isEnabled = rig.getAttribute("movement-controls").constrainToNavMesh;
        rig.setAttribute("movement-controls", "constrainToNavMesh", isEnabled ? "false" : "true");
    }
    toggleMenu() {
        const state = this.getVRState();
        const menuEntity = document.getElementById("menu-entity");
        const objectsCircleEntity = document.getElementById("objects-circle-entity");
        const menu = document.getElementById("menu");
        const menuSky = document.getElementById("menu-sky");
        const navBar = document.getElementById("nav-bar");
        const audioButton = document.getElementById("audio-button");
        const videoButton = document.getElementById("video-button");
        if (audioButton) {
            audioButton.setAttribute("src", state.hasMicrophone ? "#voice-icon-on" : "#voice-icon-off");
        }
        if (videoButton) {
            videoButton.setAttribute("src", state.hasCamera ? "#video-icon-on" : "#video-icon-off");
        }
        const isMenuVisible = !state.isInMenu;
        state.isInMenu = isMenuVisible;
        this.setVRState(state);
        this.toggleVisibility(menuEntity, isMenuVisible);
        this.toggleClickable(navBar, isMenuVisible);
        this.toggleVisibility(menuSky, isMenuVisible);
        this.toggleVisibility(objectsCircleEntity, false);
        this.toggleNAFEntitiesVisibility(!isMenuVisible);
        if (isMenuVisible) {
            const { position, rotation } = this.getPlayerPositionAndRotation();
            state.positionBeforeTeleport = position;
            state.rotationBeforeTeleport = rotation;
            this.setVRState(state);
            this.teleportToMenu();
            this.toggleNavMesh();
        }
        else {
            this.updatePositionAndRotation(state.positionBeforeTeleport, state.rotationBeforeTeleport);
            this.toggleNavMesh();
        }
    }
    teleportToMenu() {
        const menu = document.getElementById("menu-entity");
        const rig = document.getElementById("rig");
        const targetObject3D = menu.object3D;
        const boundingBox = new AFRAME.THREE.Box3().setFromObject(targetObject3D);
        const targetCenter = new AFRAME.THREE.Vector3();
        boundingBox.getCenter(targetCenter);
        const direction = new AFRAME.THREE.Vector3(0, 0, 5);
        direction.applyQuaternion(targetObject3D.quaternion);
        const teleportPosition = targetCenter.clone().add(direction);
        teleportPosition.setY(rig.object3D.position.y); // keep height
        const teleportRotation = targetObject3D.rotation;
        this.updatePositionAndRotation(teleportPosition, teleportRotation);
    }
    updatePositions(asset) {
        // Update positions:
        const targetElement = document.getElementById(`${asset._id}-entity`);
        const rigElement = document.getElementById("rig");
        // Get the bounding box of the target
        const targetObject3D = targetElement.object3D;
        const boundingBox = new AFRAME.THREE.Box3().setFromObject(targetObject3D);
        // Get the center
        const targetPosition = new AFRAME.THREE.Vector3();
        boundingBox.getCenter(targetPosition);
        // Set the position direction as 1 meter in front of the target
        const positionDirection = new AFRAME.THREE.Vector3(0, 0, 1);
        // Apply the rotation matrix to the direction vector
        positionDirection.applyQuaternion(targetObject3D.quaternion);
        // Calculate the camera position as the target position + the direction vector
        const cameraPosition = targetPosition.clone().add(positionDirection);
        // Keep the initial y position, we don't want to modify it.
        cameraPosition.setY(rigElement.object3D.position.y);
        this.updatePosition(cameraPosition);
        // -- Rotation:
        // Get the rig's initial rotation
        const rigQuaternion = rigElement.object3D.quaternion.clone();
        // Invert the rig's quaternion to reverse its rotation effect
        const invertedRigQuaternion = rigQuaternion.clone().invert();
        // Apply the inverted rig rotation to the target's quaternion
        // The order is crucial - when doing Q1 * Q2, Q1 is applied first.
        // We first want to undo the rotation done by the rig, and then apply the target's rotation.
        const targetQuaternion = targetObject3D.quaternion.clone();
        // Ignore the Z rotation as it messes with the overall rotation, and in reality we don't care about it.
        const targetEuler = new AFRAME.THREE.Euler().setFromQuaternion(targetQuaternion, "YXZ");
        targetEuler.z = 0;
        targetQuaternion.setFromEuler(targetEuler);
        const adjustedQuaternion = invertedRigQuaternion.multiply(targetQuaternion);
        // Calculate the Euler angles from the adjusted quaternion
        const finalEuler = new AFRAME.THREE.Euler().setFromQuaternion(adjustedQuaternion, "YXZ");
        // Calculate the pitch angle as the angle between the target and the camera
        // using atan2(difference in y, difference in (x + z))
        const deltaX = Math.sqrt(Math.pow(targetPosition.x - cameraPosition.x, 2) +
            Math.pow(targetPosition.z - cameraPosition.z, 2));
        const deltaY = targetPosition.y - (cameraPosition.y + 1.6);
        finalEuler.x = Math.atan2(deltaY, deltaX);
        this.updateRotation(finalEuler);
    }
    getAssetItemMediaId(asset) {
        if (!asset.product) {
            return null;
        }
        const { mediaType, typeFor3D } = asset;
        if (mediaType === AssetMediaRenderType.IMAGE_2D) {
            return asset._id;
        }
        if (mediaType === AssetMediaRenderType.IMAGE_360) {
            return asset._id;
        }
        if (mediaType === AssetMediaRenderType.IMAGE_2D_CAROUSEL) {
            return `${asset._id}-0`;
        }
        if (mediaType === AssetMediaRenderType.IMAGE_360_CAROUSEL) {
            return `${asset._id}-0`;
        }
        if (mediaType === AssetMediaRenderType.ITEM_3D) {
            if (typeFor3D === AssetMediaTypeFor3D.OBJ_MTL) {
                return `${asset._id}`;
            }
            if (typeFor3D === AssetMediaTypeFor3D.GLB) {
                return `${asset._id}`;
            }
        }
        if (mediaType === AssetMediaRenderType.VIDEO) {
            return asset._id;
        }
    }
    isVR() {
        const isMobile = AFRAME.utils.device.isMobile();
        const isVR = AFRAME.utils.device.checkHeadsetConnected() && !isMobile;
        return isVR;
    }
    cleanupAssetsListeners(selector) {
        const assets = document.querySelectorAll(selector);
        for (const asset of assets) {
            const id = asset.getAttribute("id");
            const projectAssetInfoId = id.substring(0, id.indexOf("-"));
            if (projectAssetInfoId === "world") {
                continue;
            }
            this.vrAssetService.removeListener(id, "click");
        }
    }
    setupAssetsListeners(selector) {
        const assets = document.querySelectorAll(selector);
        for (const asset of assets) {
            const id = asset.getAttribute("id");
            const projectAssetInfoId = id.substring(0, id.indexOf("-"));
            if (projectAssetInfoId === "world") {
                continue;
            }
            this.vrAssetService.addListener(id, "click", () => {
                this.eventManager.emit(new AssetClickedEvent({
                    elementId: id,
                    projectAssetInfoId,
                }));
            });
        }
    }
    // TODO: separate & cleanup
    onAssetClickedVR(e) {
        if (!this.isVR()) {
            return;
        }
        const { projectAssetInfoId, elementId } = e.data;
        const asset = this.project.assetInfos.find((asset) => asset._id === projectAssetInfoId);
        const assetElement = document.getElementById(elementId);
        const assetMenu = document.getElementById("asset-menu");
        const isFromObjectsCircleEntity = elementId.includes("circle-entity");
        if (isFromObjectsCircleEntity) {
            assetMenu.setAttribute("look-at", "#player");
        }
        else {
            assetMenu.removeAttribute("look-at");
        }
        if (this.state.currentlySelectedAssetId !== asset._id) {
            this.toggleVisibility(assetMenu, true);
            this.toggleClickable(assetMenu, true);
        }
        else {
            this.toggleVisibility(assetMenu, false);
            this.toggleClickable(assetMenu, false);
        }
        const boundingBox = new AFRAME.THREE.Box3().setFromObject(assetElement.object3D);
        const assetPosition = new AFRAME.THREE.Vector3();
        const assetRotation = assetElement.object3D.rotation;
        // assetElement.object3D.getWorldPosition(assetPosition);
        boundingBox.getCenter(assetPosition);
        const assetScale = assetElement.getAttribute("scale");
        const quaternion = assetElement.object3D.quaternion;
        const forwardOffset = new AFRAME.THREE.Vector3(0, 0, 1);
        forwardOffset.applyQuaternion(quaternion);
        forwardOffset.multiplyScalar(0.05);
        const menuPosition = {
            x: assetPosition.x + forwardOffset.x,
            y: assetPosition.y + forwardOffset.y,
            z: assetPosition.z + forwardOffset.z,
        };
        assetMenu.object3D.position.set(menuPosition.x, menuPosition.y, menuPosition.z);
        assetMenu.object3D.rotation.set(assetRotation.x, assetRotation.y, assetRotation.z);
        assetMenu.object3D.scale.set(assetScale.x, assetScale.y, assetScale.z);
        const assetMenuWalkButtonElementId = "asset-menu-walk-button";
        const assetMenuWishlistButtonElementId = "asset-menu-wishlist-button";
        const assetMenuWishlistButtonTextElementId = "asset-menu-wishlist-button-text";
        const assetMenuWishlistButtonImageElementId = "asset-menu-wishlist-button-image";
        const assetMenuDetailsButtonElementId = "asset-menu-details-button";
        const assetMenuCarouselBarElementId = "asset-menu-carousel-bar";
        const assetMenuVideoBarElementId = "asset-menu-video-bar";
        const assetMenuPrevEndButtonElementId = "asset-menu-prev-end-button";
        const assetMenuPrevButtonElementId = "asset-menu-prev-button";
        const assetMenuNextButtonElementId = "asset-menu-next-button";
        const assetMenuNextEndButtonElementId = "asset-menu-next-end-button";
        const assetMenu360PlayButtonElementId = "asset-menu-360-play-button";
        const assetMenuCloseButtonElementId = "asset-menu-close-button";
        const assetMenuVerticalBarElementId = "asset-menu-vertical-bar";
        const assetMenuVideoPlayPauseButtonElementId = "asset-menu-video-play-pause-button";
        const leftHandElementId = "left-hand";
        const rightHandElementId = "right-hand";
        const onCloseAssetMenu = () => {
            const assetMenu = document.getElementById("asset-menu");
            this.toggleVisibility(assetMenu, false);
            this.toggleClickable(assetMenu, false);
        };
        this.vrAssetService.addListener(assetMenuCloseButtonElementId, "click", onCloseAssetMenu);
        this.vrAssetService.addListener(assetMenuWalkButtonElementId, "click", () => {
            if (isFromObjectsCircleEntity) {
                onCloseAssetMenu();
                this.toggleMenu();
            }
            this.onTeleportToAsset(e.data.projectAssetInfoId);
        });
        this.vrAssetService.addListener(leftHandElementId, "xbuttonup", () => {
            this.assetService.showSingle360(asset);
            this.vrAssetService.addListener(leftHandElementId, "xbuttonup", this.toggleMenu);
            this.vrAssetService.removeListener(leftHandElementId, "xbuttonup");
        });
        this.vrAssetService.addListener(rightHandElementId, "abuttonup", () => {
            this.assetService.showSingle360(asset);
            this.vrAssetService.addListener(rightHandElementId, "abuttonup", this.toggleMenu);
            this.vrAssetService.removeListener(rightHandElementId, "abuttonup");
        });
        this.vrAssetService.addListener(assetMenuVideoPlayPauseButtonElementId, "click", () => {
            this.assetService.playPauseVideo(asset);
        });
        const assetMenuWishlistButton = document.getElementById(assetMenuWishlistButtonElementId);
        const assetMenuWishlistButtonImage = document.getElementById(assetMenuWishlistButtonImageElementId);
        const assetMenuCarouselBar = document.getElementById(assetMenuCarouselBarElementId);
        const assetMenuVideoBar = document.getElementById(assetMenuVideoBarElementId);
        const assetMenuWishlistButtonText = document.getElementById(assetMenuWishlistButtonTextElementId);
        const assetMenuVerticalBar = document.getElementById(assetMenuVerticalBarElementId);
        const assetMenu360PlayButton = document.getElementById(assetMenu360PlayButtonElementId);
        this.vrAssetService.addListener(assetMenu360PlayButtonElementId, "click", () => {
            this.assetService.showSingle360(asset);
            onCloseAssetMenu();
            // TODO: show a small navbar for this, maybe, with close button and left/right like in carousel.
        });
        const isCarousel = asset.mediaType === AssetMediaRenderType.IMAGE_2D_CAROUSEL ||
            asset.mediaType === AssetMediaRenderType.IMAGE_360_CAROUSEL;
        const is360 = asset.mediaType === AssetMediaRenderType.IMAGE_360 ||
            asset.mediaType === AssetMediaRenderType.IMAGE_360_CAROUSEL;
        const isVideo = asset.mediaType === AssetMediaRenderType.VIDEO;
        this.toggleVisibility(assetMenuCarouselBar, isCarousel);
        this.toggleVisibility(assetMenu360PlayButton, is360);
        this.toggleVisibility(assetMenuVideoBar, isVideo);
        this.emitChangeVisibleEvent(assetMenuVerticalBar);
        this.emitChangeVisibleEvent(assetMenuCarouselBar);
        const onWishlistChange = (isInWishlist) => {
            const newAssetMenuWishlistButtonImage = assetMenuWishlistButtonImage.cloneNode(true);
            if (isInWishlist) {
                newAssetMenuWishlistButtonImage.setAttribute("src", "#icon-trash");
                assetMenuWishlistButtonText.setAttribute("value", "Remove from Wishlist");
            }
            else {
                newAssetMenuWishlistButtonImage.setAttribute("src", "#icon-basket");
                assetMenuWishlistButtonText.setAttribute("value", "Add to wishlist");
            }
            assetMenuWishlistButtonImage.parentNode.replaceChild(newAssetMenuWishlistButtonImage, assetMenuWishlistButtonImage);
        };
        onWishlistChange(this.wishlistSmart.hasInWishlist(asset.product._id));
        this.vrAssetService.addListener(assetMenuWishlistButtonElementId, "click", () => {
            if (this.wishlistSmart.hasInWishlist(asset.product._id)) {
                onWishlistChange(false);
                this.wishlistSmart.removeFromWishlist({
                    projectId: asset.projectId,
                    productId: asset.product._id,
                });
            }
            else {
                onWishlistChange(true);
                this.wishlistSmart.addToWishlist({
                    projectId: asset.projectId,
                    productId: asset.product._id,
                });
            }
        });
        this.vrAssetService.addListener(assetMenuPrevEndButtonElementId, "click", () => {
            this.assetService.handleCarousel(asset, "first", asset.mediaType);
        });
        this.vrAssetService.addListener(assetMenuPrevButtonElementId, "click", () => {
            this.assetService.handleCarousel(asset, "prev", asset.mediaType);
        });
        this.vrAssetService.addListener(assetMenuNextButtonElementId, "click", () => {
            this.assetService.handleCarousel(asset, "next", asset.mediaType);
        });
        this.vrAssetService.addListener(assetMenuNextEndButtonElementId, "click", () => {
            this.assetService.handleCarousel(asset, "last", asset.mediaType);
        });
    }
    emitChangeVisibleEvent(element) {
        element.dispatchEvent(new CustomEvent("change-visible"));
    }
    getVRState() {
        return JSON.parse(document.querySelector("a-scene").getAttribute("state"));
    }
    setVRState(state) {
        document
            .querySelector("a-scene")
            .setAttribute("state", JSON.stringify(state));
    }
    toggleVisibility(element, isVisible) {
        element.setAttribute("visible", isVisible ? "true" : "false");
    }
    toggleClickable(element, isClickable) {
        const entities = element.querySelectorAll("[class*='clickable']");
        const newClass = isClickable ? "clickable" : "not-clickable";
        for (let i = 0; i < entities.length; i++) {
            entities[i].classList.remove("clickable", "not-clickable");
            entities[i].classList.add(newClass);
        }
    }
    toggleNAFEntitiesVisibility(isVisible) {
        const entities = document.querySelectorAll("a-entity[networked]");
        entities.forEach((entity) => {
            if (entity.id === "rig" || entity.id === "player") {
                return;
            }
            entity.setAttribute("visible", isVisible ? "true" : "false");
        });
    }
    onAssetClicked(e) {
        this.onAssetClickedVR(e);
        if (this.state.isWatching360) {
            return;
        }
        const { projectAssetInfoId } = e.data;
        const asset = this.project.assetInfos.find((asset) => asset._id === projectAssetInfoId);
        this.updateState({
            currentlySelectedAssetId: this.state.currentlySelectedAssetId === projectAssetInfoId
                ? null
                : asset === null || asset === void 0 ? void 0 : asset._id,
        });
        this.sendMessageToParent({
            type: "asset-clicked",
            value: {
                projectAssetInfoId: projectAssetInfoId,
            },
        });
    }
    onProductPlaced(projectAssetInfo) {
        const { _id: projectAssetInfoId, product } = projectAssetInfo;
        const assetInfos = [...this.project.assetInfos];
        const assetInfoIndex = assetInfos.findIndex((item) => item._id === projectAssetInfoId);
        const oldAsset = { ...assetInfos[assetInfoIndex] };
        oldAsset.product = product;
        oldAsset.mediaType = projectAssetInfo.mediaType;
        oldAsset.typeFor3D = projectAssetInfo.typeFor3D;
        assetInfos.splice(assetInfoIndex, 1, oldAsset);
        this.updateState({
            project: {
                ...this.state.project,
                assetInfos,
            },
        });
        if (this.state.currentlySelectedAssetId) {
            this.updateState({
                currentlySelectedAssetId: oldAsset._id,
            });
        }
        this.updateState({
            isProductSelectedFromMerchants: false,
        });
        this.toggleHighlightComponentsAndImages(false);
    }
    toggleHighlightComponentsAndImages(isVisible) {
        const highlightComponents = document.getElementsByClassName("highlight-component");
        for (let i = 0; i < highlightComponents.length; i++) {
            highlightComponents[i].setAttribute("visible", isVisible ? "true" : "false");
        }
        const allImages = document.querySelectorAll("a-image");
        for (let i = 0; i < allImages.length; i++) {
            allImages[i].setAttribute("visible", isVisible ? "false" : "true");
        }
    }
    getAssetPlacementInfo(assetInfo) {
        var _a;
        const hasProductPlaced = Boolean(assetInfo.product);
        const assetPosition = assetInfo.assetPosition;
        const mediaType = hasProductPlaced
            ? assetInfo.mediaType
            : assetInfo.assetPosition.mediaType;
        const typeFor3D = hasProductPlaced
            ? assetInfo.typeFor3D
            : assetInfo.assetPosition.typeFor3D;
        const media = hasProductPlaced
            ? assetInfo.product.media
            : assetInfo.assetPosition.media;
        const { position, scale, rotation } = hasProductPlaced
            ? this.assetService.calculatePositionWithDelta(assetPosition.coordinates, assetInfo.deltaCoordinates)
            : assetPosition.coordinates;
        const id = (_a = assetInfo._id) !== null && _a !== void 0 ? _a : assetInfo.assetPosition._id;
        return {
            id,
            mediaType,
            typeFor3D,
            media,
            position,
            scale,
            rotation,
        };
    }
    onProductRemoved(projectAssetInfoId) {
        const assetInfos = [...this.state.project.assetInfos];
        const assetInfoIndex = assetInfos.findIndex((item) => item._id === projectAssetInfoId);
        const oldAsset = { ...assetInfos[assetInfoIndex] };
        delete oldAsset.product;
        assetInfos.splice(assetInfoIndex, 1, oldAsset);
        this.updateState({
            project: {
                ...this.state.project,
                assetInfos,
            },
        });
    }
    onTeleportToAsset(projectAssetInfoId) {
        this.updatePositions(this.project.assetInfos.find((asset) => asset._id === projectAssetInfoId));
    }
    onAssetPositionsChange(positions, assetId) {
        const entity = this.assetService.findWorldAssetEntityById(assetId);
        const originalPositions = this.project.assetInfos.find((info) => info._id === assetId).assetPosition.coordinates;
        this.assetService.updatePositionsForAsset(entity, this.assetService.calculatePositionWithDelta(positions, originalPositions));
    }
    onAssetPositionsUpdated(deltaPositions, assetId) {
        // TODO: update the position in the item, in state
        const assetInfos = [...this.state.project.assetInfos];
        const assetInfoIndex = assetInfos.findIndex((item) => item._id === assetId);
        const oldAsset = { ...assetInfos[assetInfoIndex] };
        oldAsset.deltaCoordinates = deltaPositions;
        assetInfos.splice(assetInfoIndex, 1, oldAsset);
        this.updateState({
            project: {
                ...this.state.project,
                assetInfos,
            },
        });
    }
    async onLogin() {
        const isOwnerOfProject = this.state.isOwnerOfProject
            ? true
            : this.isOwnerOfProject(this.state.project);
        this.updateState({
            isOwnerOfProject,
        });
        if (!this.wishlistSmart.state.initialised) {
            await this.wishlistSmart.init();
        }
        this.wishlistSmart.updateWishlist();
    }
    async onLogout() {
        await this.guardian.logout();
        this.updateState({
            isLoggedIn: false,
            isOwnerOfProject: undefined,
        });
        this.enterProject();
    }
    switchCamera() {
        this.updateState({
            hasCamera: !this.state.hasCamera,
        });
        const aSceneState = this.getVRState();
        aSceneState.hasCamera = this.state.hasCamera;
        this.setVRState(aSceneState);
    }
    switchMicrophone() {
        this.updateState({
            hasMicrophone: !this.state.hasMicrophone,
        });
        const aSceneState = this.getVRState();
        aSceneState.hasMicrophone = this.state.hasMicrophone;
        this.setVRState(aSceneState);
    }
    onProductSelectedFromMerchants(isProductSelectedFromMerchants) {
        this.toggleHighlightComponentsAndImages(isProductSelectedFromMerchants);
        this.updateState({ isProductSelectedFromMerchants });
    }
    degreesToRadians(degrees) {
        return degrees * (Math.PI / 180);
    }
    sendMessageToParent(data) {
        if (!this.state.isEmbedded)
            return;
        parent.postMessage(data, {
            targetOrigin: env.MERCHANTS_APP_URL,
        });
    }
    onLanguageChange(lang) {
        this.uiSessionService.set("currentLocale", lang, { persist: true });
        this.i18nService.setLocale(lang);
        this.router.history.push({
            ...this.router.history.location,
            search: `?lang=${lang}`,
        });
    }
    switchIsChatting() {
        this.updateState({
            isChatting: !this.state.isChatting,
        });
    }
    async messageEventListener(event) {
        if (event.origin !== env.MERCHANTS_APP_URL)
            return;
        const data = event.data;
        switch (data.type) {
            case "switchCamera":
                this.switchCamera();
                break;
            case "switchMicrophone":
                this.switchMicrophone();
                break;
            case "product-placed":
                this.onProductPlaced(data.value.projectAssetInfo);
                break;
            case "set-auth-token":
                await this.guardian.storeToken(data.token);
                await this.guardian.load();
                this.updateState({
                    hasReceivedInitFromMerchants: true,
                });
                break;
            case "product-removed":
                this.onProductRemoved(data.value.projectAssetInfoId);
                break;
            case "teleport-to-asset":
                this.onTeleportToAsset(data.assetInfoId);
                break;
            case "asset-clicked":
                this.onProductSelectedFromMerchants(data.selected);
                break;
            case "positions-changed":
                this.onAssetPositionsChange(data.positions, data.assetId);
                break;
            case "positions-updated":
                this.onAssetPositionsUpdated(data.positions, data.assetId);
                break;
            case "language-changed":
                this.onLanguageChange(data.language);
                break;
        }
    }
    async submitLog(primaryData) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9;
        const getNavigatorInfo = () => {
            const navigatorInfo = {};
            try {
                for (const nav in navigator) {
                    if (typeof navigator[nav] !== "object" || nav === "userAgentData") {
                        navigatorInfo[nav] = navigator[nav];
                    }
                }
            }
            catch { }
            return navigatorInfo;
        };
        const getScreenInfo = () => {
            const screenInfo = {};
            try {
                for (const loc in screen) {
                    screenInfo[loc] = screen[loc];
                }
            }
            catch { }
            return screenInfo;
        };
        try {
            const secondaryData = {
                project: {
                    id: (_a = this.project) === null || _a === void 0 ? void 0 : _a._id,
                    name: (_b = this.project) === null || _b === void 0 ? void 0 : _b.name,
                },
                world: {
                    id: (_d = (_c = this.project) === null || _c === void 0 ? void 0 : _c.world) === null || _d === void 0 ? void 0 : _d._id,
                    name: (_g = (_f = (_e = this.project) === null || _e === void 0 ? void 0 : _e.world) === null || _f === void 0 ? void 0 : _f.name) === null || _g === void 0 ? void 0 : _g.text,
                },
                guardian: {
                    state: {
                        isLoggedIn: (_j = (_h = this.guardian) === null || _h === void 0 ? void 0 : _h.state) === null || _j === void 0 ? void 0 : _j.isLoggedIn,
                        hasInvalidToken: (_l = (_k = this.guardian) === null || _k === void 0 ? void 0 : _k.state) === null || _l === void 0 ? void 0 : _l.hasInvalidToken,
                        user: {
                            id: (_p = (_o = (_m = this.guardian) === null || _m === void 0 ? void 0 : _m.state) === null || _o === void 0 ? void 0 : _o.user) === null || _p === void 0 ? void 0 : _p._id,
                            email: (_s = (_r = (_q = this.guardian) === null || _q === void 0 ? void 0 : _q.state) === null || _r === void 0 ? void 0 : _r.user) === null || _s === void 0 ? void 0 : _s.email,
                            fullName: (_v = (_u = (_t = this.guardian) === null || _t === void 0 ? void 0 : _t.state) === null || _u === void 0 ? void 0 : _u.user) === null || _v === void 0 ? void 0 : _v.fullName,
                            role: (_z = (_y = (_x = (_w = this.guardian) === null || _w === void 0 ? void 0 : _w.state) === null || _x === void 0 ? void 0 : _x.user) === null || _y === void 0 ? void 0 : _y.roles) === null || _z === void 0 ? void 0 : _z[0],
                        },
                    },
                },
                state: {
                    isEmbedded: (_0 = this.state) === null || _0 === void 0 ? void 0 : _0.isEmbedded,
                    isFromMerchantsHeader: (_1 = this.state) === null || _1 === void 0 ? void 0 : _1.isFromMerchantsHeader,
                    isProductSelectedFromMerchants: (_2 = this.state) === null || _2 === void 0 ? void 0 : _2.isProductSelectedFromMerchants,
                    isChatting: (_3 = this.state) === null || _3 === void 0 ? void 0 : _3.isChatting,
                    hasMicrophone: (_4 = this.state) === null || _4 === void 0 ? void 0 : _4.hasMicrophone,
                    hasCamera: (_5 = this.state) === null || _5 === void 0 ? void 0 : _5.hasCamera,
                },
                uiSessionServiceState: {
                    projectLinkName: (_7 = (_6 = this.uiSessionService) === null || _6 === void 0 ? void 0 : _6.state) === null || _7 === void 0 ? void 0 : _7.projectLinkName,
                    currentLocale: (_9 = (_8 = this.uiSessionService) === null || _8 === void 0 ? void 0 : _8.state) === null || _9 === void 0 ? void 0 : _9.currentLocale,
                },
                canUseVideo: this.canUseVideo(),
                canUseAudio: this.canUseAudio(),
                canUseChat: this.canUseChat(),
                isOwnerOfProject: this.isOwnerOfProject(this.state.project),
                vrMicroservice: {
                    buildDate: env === null || env === void 0 ? void 0 : env.BUILD_DATE,
                    buildVersion: env === null || env === void 0 ? void 0 : env.BUILD_VERSION,
                },
                window: {
                    navigator: getNavigatorInfo(),
                    screen: getScreenInfo(),
                    performance,
                    location,
                },
            };
            const dataToLog = {
                primary: primaryData,
                secondary: secondaryData,
            };
            const res = await this.apolloClient.mutate({
                mutation: SUBMIT_LOG,
                variables: {
                    input: {
                        action: SubmitLogInputAction.INFO,
                        microservice: SubmitLogInputMicroservice.VR,
                        data: dataToLog,
                    },
                },
            });
            return res;
        }
        catch (err) {
            console.error({ msg: "Error in SubmitLog", err });
            try {
                await this.apolloClient.mutate({
                    mutation: SUBMIT_LOG,
                    variables: {
                        input: {
                            action: SubmitLogInputAction.EXCEPTION,
                            microservice: SubmitLogInputMicroservice.VR,
                            data: { msg: "Error in SubmitLog", err: err === null || err === void 0 ? void 0 : err.toString() },
                        },
                    },
                });
            }
            catch { }
            return null;
        }
    }
};
__decorate([
    Inject(() => AppGuardian),
    __metadata("design:type", typeof (_a = typeof AppGuardian !== "undefined" && AppGuardian) === "function" ? _a : Object)
], ProjectSmart.prototype, "guardian", void 0);
__decorate([
    Inject(() => EventManager),
    __metadata("design:type", typeof (_b = typeof EventManager !== "undefined" && EventManager) === "function" ? _b : Object)
], ProjectSmart.prototype, "eventManager", void 0);
__decorate([
    Inject(),
    __metadata("design:type", typeof (_c = typeof ApolloClient !== "undefined" && ApolloClient) === "function" ? _c : Object)
], ProjectSmart.prototype, "apolloClient", void 0);
__decorate([
    Inject(() => AssetService),
    __metadata("design:type", typeof (_d = typeof AssetService !== "undefined" && AssetService) === "function" ? _d : Object)
], ProjectSmart.prototype, "assetService", void 0);
__decorate([
    Inject(),
    __metadata("design:type", typeof (_e = typeof UISessionService !== "undefined" && UISessionService) === "function" ? _e : Object)
], ProjectSmart.prototype, "uiSessionService", void 0);
__decorate([
    Inject(),
    __metadata("design:type", typeof (_f = typeof I18NService !== "undefined" && I18NService) === "function" ? _f : Object)
], ProjectSmart.prototype, "i18nService", void 0);
__decorate([
    Inject(),
    __metadata("design:type", typeof (_g = typeof VRAssetService !== "undefined" && VRAssetService) === "function" ? _g : Object)
], ProjectSmart.prototype, "vrAssetService", void 0);
__decorate([
    Inject(),
    __metadata("design:type", typeof (_h = typeof XRouter !== "undefined" && XRouter) === "function" ? _h : Object)
], ProjectSmart.prototype, "router", void 0);
__decorate([
    Inject(() => WishlistSmart),
    __metadata("design:type", typeof (_j = typeof WishlistSmart !== "undefined" && WishlistSmart) === "function" ? _j : Object)
], ProjectSmart.prototype, "wishlistSmart", void 0);
ProjectSmart = __decorate([
    Service(),
    __metadata("design:paramtypes", [])
], ProjectSmart);
export { ProjectSmart };
