import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { newSmart, useEventManager, useRouter, useSmart, useTranslate, useUIComponents, useUISession, } from "@bluelibs/x-ui";
import { Routes } from "@bundles/UIAppBundle";
import { ProjectLogin } from "@bundles/UIAppBundle/components";
import { AssetClickedEvent } from "@bundles/UIAppBundle/events/AssetClicked.event";
import { useAppGuardian } from "@bundles/UIAppBundle/services/AppGuardian";
import { AcceptedLanguage } from "@root/api.types";
import React, { Fragment, useEffect } from "react";
import { components } from "./aframe-components";
import "./Project.scss";
import { ChatSmart } from "@bundles/UIAppBundle/smarts/Chat.smart";
import { parse } from "querystring";
import "./aframe-components/index";
import Loader from "@bundles/UIAppBundle/components/loader/Loader";
import { NotFound } from "@bundles/UIAppBundle/overrides";
import { ProjectSmart } from "@bundles/UIAppBundle/smarts/Project.smart";
import { WishlistSmart } from "@bundles/UIAppBundle/smarts/Wishlist.smart";
import { toast } from "react-toastify";
import { ProjectMobileFullscreen } from "./ProjectMobileFullscreen";
import { AFrameVRComponent } from "./VRComponent";
import { AFrameVRComponentMerchants } from "./VRComponentMerchants";
export const ProjectPageWrapper = (props) => {
    const [_, OpenViduSmartProvider] = newSmart(ChatSmart);
    const [_2, ProjectSmartProvider] = newSmart(ProjectSmart);
    const [_3, WishlistSmartProvider] = newSmart(WishlistSmart);
    const isMobile = window.AFRAME ? AFRAME.utils.device.isMobile() : false;
    return (_jsx(ProjectSmartProvider, { children: _jsx(WishlistSmartProvider, { children: _jsxs(OpenViduSmartProvider, { children: [_jsx(ProjectPage, Object.assign({}, props), void 0), isMobile && _jsx(ProjectMobileFullscreen, {}, void 0)] }, void 0) }, void 0) }, void 0));
};
export const ProjectPage = ({ linkName }) => {
    var _a, _b;
    const t = useTranslate("pages.project");
    const UIComponents = useUIComponents();
    const eventManager = useEventManager();
    const guardian = useAppGuardian();
    const projectSmart = useSmart(ProjectSmart);
    const wishlistSmart = useSmart(WishlistSmart);
    const uiSession = useUISession();
    const state = projectSmart.state;
    const router = useRouter();
    const project = projectSmart.project;
    // Don't try to fetch the project if you're embedded but not logged in, yet
    // It means the login token didn't arrive in VR (so to speak)
    useEffect(() => {
        if (state.isEmbedded && !guardian.state.isLoggedIn)
            return;
        projectSmart.updateState({ linkName });
        projectSmart.enterProject();
    }, [guardian.state.isLoggedIn]);
    useEffect(() => {
        if (projectSmart.state.project) {
            wishlistSmart.updateWishlist();
        }
    }, [projectSmart.state.project]);
    useEffect(() => {
        if (!project)
            return;
        uiSession.set("projectId", project._id, { persist: true });
        uiSession.set("merchantId", project.merchantId, { persist: true });
        uiSession.set("projectLinkName", linkName, { persist: true });
    }, [project]);
    const onLeave = () => {
        window.open(Routes.Wishlist.path, "blank");
    };
    const onInquiry = () => {
        window.open(Routes.Wishlist.path, "blank");
    };
    const onLogout = async () => {
        try {
            await projectSmart.onLogout();
            await wishlistSmart.onLogout();
            toast.info(t("successLogout"));
        }
        catch (err) {
            toast.error(err.toString());
        }
    };
    // initialise language from query param (if possible)
    useEffect(() => {
        const { lang } = parse(router.history.location.search.substring(1));
        if (lang) {
            uiSession.set("currentLocale", lang, {
                persist: true,
            });
        }
        else {
            uiSession.set("currentLocale", AcceptedLanguage.en, {
                persist: true,
            });
        }
    }, []);
    // setup user state
    useEffect(() => {
        projectSmart.onLogin();
    }, [guardian.state.isLoggedIn, project]);
    // setup listeners & eventManager
    useEffect(() => {
        eventManager.addListener(AssetClickedEvent, projectSmart.onAssetClicked);
        projectSmart.sendMessageToParent({
            type: "pre-initialise",
        });
        window.addEventListener("message", projectSmart.messageEventListener);
        return () => {
            eventManager.removeListener(AssetClickedEvent, projectSmart.onAssetClicked);
            window.removeEventListener("message", projectSmart.messageEventListener);
        };
    }, [projectSmart.state.isEmbedded]);
    // register components
    useEffect(() => {
        try {
            for (const [componentName, component] of Object.entries(components)) {
                AFRAME.registerComponent(componentName, component);
            }
        }
        catch {
            // they have already been registered
        }
    }, []);
    const error = state.errorProject;
    if (error) {
        const code = (_b = (_a = error.graphQLErrors) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.code;
        switch (code) {
            case "PROJECT_NOT_LIVE":
                return (_jsxs(Fragment, { children: [_jsx(NotFound, { withLogin: true, context: "project-not-live" }, void 0), _jsx("div", Object.assign({ style: { display: "none" } }, { children: _jsx(ProjectLogin, { onFinishLogin: projectSmart.enterProject }, void 0) }), void 0)] }, void 0));
            case "PROJECT_DOES_NOT_EXIST":
                return _jsx(NotFound, { context: "project-not-found" }, void 0);
        }
        return _jsx(UIComponents.Error, { error: error.toString() }, void 0);
    }
    if (state.isLoadingProject ||
        state.project === undefined ||
        !guardian.state.initialised) {
        return _jsx(Loader, { centerInScreen: true }, void 0);
    }
    if (!project)
        return _jsx(UIComponents.NotAuthorized, {}, void 0);
    const isEditingProjectFromMerchants = state.isEmbedded && !state.isFromMerchantsHeader && state.isOwnerOfProject;
    if (isEditingProjectFromMerchants) {
        return _jsx(AFrameVRComponentMerchants, {}, void 0);
    }
    return (_jsx(AFrameVRComponent, Object.assign({}, {
        onLogout,
        onLeave,
        onInquiry,
    }), void 0));
};
